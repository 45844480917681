import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WorkerStateService } from '../../../core/services';
import { Worker } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class ZenmodeAbTestService {
  // Константные списки
  private readonly allowedOffices: number[] = [
    7, // samal
    17, // elite
    5, // poedem dostyk
  ];
  private readonly allowedEmployeeIds: number[] = [100];
  private readonly onlyZenmodeOffices: number[] = [];
  private readonly onlyZenmodeEmployeeIds: number[] = [];

  public isAvailableZenmode$: Observable<boolean> = this.workerState.currentWorker$.pipe(
    tap(worker => {
      const isAvailable = this.isZenModeAvailableForWorker(worker);
      console.log(`Доступность Zen Mode для ${worker.username} в офисе ${worker.office.id}: ${isAvailable}`);
    }),
    map((worker: Worker) => this.isZenModeAvailableForWorker(worker)),
  );

  public isOnlyZenmodeForWorker$: Observable<boolean> = this.workerState.currentWorker$.pipe(
    tap(worker => {
      const isOnlyZenmode = this.isOnlyZenmodeForWorker(worker);
      console.log(`Only Zen Mode для ${worker.username} в офисе ${worker.office.id}: ${isOnlyZenmode}`);
    }),
    map((worker: Worker) => this.isOnlyZenmodeForWorker(worker)),
  );

  constructor(private workerState: WorkerStateService) {}

  private isZenModeAvailableForWorker(worker: Worker): boolean {
    return (
      this.allowedOffices.includes(worker.office.id) ||
      this.allowedEmployeeIds.includes(worker.employeeId) ||
      this.isOnlyZenmodeForWorker(worker)
    );
  }

  private isOnlyZenmodeForWorker(worker: Worker): boolean {
    return (
      this.onlyZenmodeOffices.includes(worker.office.id) ||
      this.onlyZenmodeEmployeeIds.includes(worker.employeeId)
    );
  }
}
